import React from 'react'
import Header from '../../Components/Header/Header'
import HeaderLinks from '../../Components/Header/HeaderLinks'
import { makeStyles } from '@material-ui/core';
import classNames from "classnames";

import styles from "../../assets/jss/material-kit-react/views/components";
import Footer from '../../Components/Footer/Footer';
import PoliticSecction from './PoliticSection';
const useStyles = makeStyles(styles);

export default function Politics(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (
        <div>
            <Header
                brand="ICTH"
                rightLinks={<HeaderLinks external />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <div className={classNames(classes.mainMargin, classes.top)}>
                <PoliticSecction />
            </div>
            <Footer />
        </div>
    )
}