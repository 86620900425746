import React from 'react'
import classNames from "classnames";
import HeaderLinks from '../../Components/Header/HeaderLinks';
import GridContainer from "../../Components/Grid/GridContainer.js";
import GridItem from "../../Components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import logo from './icth-logo-2.png'
import headerBg from './header.jpg'
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Parallax from '../../Components/Parallax/Parallax';
import SectionGoodnessCredits from './Sections/SectionGoodnessCredits';
import SectionAboutUs from './Sections/SectionAboutUs';
import SectionOurServices from './Sections/SectionOurServices';
import SectionContact from './Sections/SectionContact';

import styles from "../../assets/jss/material-kit-react/views/components";
const useStyles = makeStyles(styles);

export default function Home(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
        <div>
            <Header
                brand="ICTH"
                rightLinks={<HeaderLinks />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax image={headerBg}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem>
                            <div className={classes.brand}>
                                <h1 className={classes.title}>
                                    <img className={classes.logo} src={logo} alt="logo" />
                                </h1>
                                <h3 className={classes.subtitle}></h3>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            
            <div className={classNames(classes.main, classes.mainRaised)}>
                <SectionGoodnessCredits />
                <SectionAboutUs />
                <SectionOurServices />
                <SectionContact />
            </div>
            <Footer />
        </div>
    )
}