import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Home from './View/Home/Home'
import Politics from './View/Politics/Politics'
import Login from './View/Login/Login'
import Dash from './View/Dash/Dash'
import { PrivateRoute } from './Components/PrivateRoute/PrivateRoute'
import OrganizationChart from './View/OrganizationChart/OrganizationChart'
export const history = createBrowserHistory()

const mainRouter = [
  { path: "/", component: Home, private: false, exact: true },
  { path: "/politicas", component: Politics, private: false, exact: true },
  { path: "/organigrama", component: OrganizationChart, private: false, exact: true },
  { path: "/ingresar", component: Login, private: false, exact: true },
  { path: "/app", component: Dash, private: true, exact: false },
]

export default function App(props) {
  return (
    <Router history={history}>
      <Switch>
        {
          mainRouter.map((prop, key) => {
            return prop.private ?
              <PrivateRoute exact={prop.exact} path={prop.path} component={prop.component} key={key} /> :
              <Route exact={prop.exact} path={prop.path} component={prop.component} key={key} />
          })
        }
      </Switch>
    </Router>
  )
}
