import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import GridContainer from '../../../Components/Grid/GridContainer.js';
import GridItem from '../../../Components/Grid/GridItem.js';

const useStyles = makeStyles(styles);

export default function SectionOurServices(props) {
    const classes = useStyles();
    return (
        <div id="ourServices" className={classes.section + ' ' + classes.sectionOurServices}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 className={classes.title + ' ' + classes.colorWhite}>Nuestro servicio</h2>
                    <h5 className={classes.description + ' ' + classes.colorWhite}>Otorgar créditos mediante el sistema de libranzas, de los bienes y valores que se comercialicen con recursos propios, y así lograr el bienestar socioeconómico de nuestros clientes y comunidad en general.</h5>
                </GridItem>
            </GridContainer>
            <div style={{
                padding: '0 20px'
            }}>
            </div>
        </div >
    )
}