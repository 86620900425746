import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import GridContainer from '../../../Components/Grid/GridContainer.js';
import GridItem from '../../../Components/Grid/GridItem.js';
import InfoArea from '../../../Components/InfoArea/InfoArea.js';
import mission from '../mission.svg'
import vision from '../vision.svg'

const useStyles = makeStyles(styles);

export default function SectionAboutUs(props) {
    const classes = useStyles();
    return (
        <div id="aboutUs" className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2  className={classes.title}>¿Quienes somos?</h2>
                </GridItem>
            </GridContainer>
            <div style={{
                padding: '0 20px'
            }}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <InfoArea
                            infoArea2
                            title="Reseña Histórica"
                            description={`La empresa INVERSIONES Y COBRANZAS TODO HOGAR ICTH SAS, fue fundada en el año 2014 la cual, en busca de darle un mayor bienestar a docentes y administrativos de entidades como La Alcaldía de Cartagena, Secretarias de Educación Distrital, Consorcio FOPEP, Fiduciaria La Previsora y Gobernación de Bolívar, realizó convenios con estas entidades para comercializar bienes y servicios, con el fin de ser recaudados a través del sistema de libranzas.`}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            infoArea2
                            title="Misión"

                        >
                            <img src={mission} alt="Mision" className={classes.img} />
                            <p>Otorgar créditos de manera rápida bajo los lineamientos de nuestra entidad, aprovechando nuestra experticia para solucionar necesidades inmediatas a nuestros clientes por el sistema de libranzas.</p>
                        </InfoArea>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            infoArea2
                            title="Visión"
                        >
                            <img src={vision} alt="Mision" className={classes.img} />
                            <p>Ser reconocidos y sostenernos en el mercado como expertos en libranzas, abarcando cada día más sectores de nuestro departamento.</p>
                        </InfoArea>
                    </GridItem>
                    <GridItem xs={12} sm={12} />
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            infoArea2
                            title="Objetivo"
                            description={`Los objetivos y las actividades de INVERSIONES Y COBRANZAS TODO HOGAR ICTH SAS, estarán encaminadas a contribuir a la satisfacción de las necesidades de docentes, administrativos y pensionados de las entidades ante las cuales estamos inscritos; promoviendo los créditos por el sistema de libranza como una solución ante las distintas situaciones económicas que se les presenten, para que permita e impulse el desarrollo y mejoramiento de la calidad de vida de nuestros clientes.`}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            infoArea2
                            title="Valores corporativos"
                        >
                            <ul>
                                <li>Amor por nuestra labor.</li>
                                <li>Respeto al personal participante en los procesos de créditos por libranza.</li>
                                <li>Transparencia desde el inicio de la negociación. </li>
                                <li>Honestidad por parte de nuestro personal </li>
                                <li>Profesionalismo en cada etapa del crédito, incluido la protección de datos.</li>
                                <li>Liderazgo para llevar a cabo las metas trazadas</li>
                                <li>Efectividad que permita la satisfacción total de clientes y gerencia</li>
                                <li>Mejoramiento continuo para lograr la excelencia.</li>
                            </ul>
                        </InfoArea>
                    </GridItem>
                </GridContainer>
            </div>

        </div >
    )
}