import React, { useEffect, useState } from 'react'
import { AppBar, Toolbar, IconButton, Paper, Table, TableHead, TableRow, TableBody, TableCell, Modal, Button, Grid } from '@material-ui/core'
import { URL_BASE } from '../../config';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import CurrencyFormat from 'react-currency-format'
import Dropzone from "react-dropzone";
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        maxWidth: '800px',
        margin: '25px auto',
        padding: '15px'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dropzone: {
        border: "3px dashed #ababab",
        height: "200px",
        padding: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: '4px',
        textAlign: 'center',
        flexDirection: "column"
    },
    buttonContainer: {
        marginTop: '25px'
    }
}));

function getModalStyle() {
    return {
        width: '100%',
        maxWidth: '700px',
        margin: '10px',
        border: 'none'
    };
}

function Dash(props) {
    const classes = useStyles();
    const [userData, setUserData] = useState(null);
    const [extracts, setExtracts] = useState([]);
    const [movements, setMovements] = useState([]);

    const [openSnak, setOpenSnak] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState('');

    const [openModal, setOpenModal] = React.useState(false);
    const [modalStyle] = React.useState(getModalStyle);

    const [fileClient, setFileClient] = useState(null);
    const [fileMovement, setFileMovement] = useState(null);

    const handleCloseSnak = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnak(false);
    };

    const token = localStorage.getItem('token');
    var headers = new Headers({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
    });

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            setUserData(JSON.parse(user))
        } else {
            fetch(`${URL_BASE}/api/user`, { method: 'GET', headers: headers })
                .then(response => response.json())
                .catch(error => { console.error('Error:', error) }).then(response => {
                    localStorage.setItem('user', JSON.stringify({ ...response }))
                    setUserData({ ...response })
                });
        }
    }, []);

    useEffect(() => {
        if (userData) {
            if (userData.rol === 'SUPER_ADMIN') {
                console.log(userData);
            } else {
                fetch(`${URL_BASE}/api/get/extracts`, { method: 'GET', headers: headers })
                    .then(response => response.json())
                    .catch(error => { console.error('Error:', error) }).then(response => {
                        setExtracts(response.extracts)
                    });
            }
        }
    }, [userData])

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenModal = (source, number) => {
        fetch(`${URL_BASE}/api/get/movements/extracts/${source}/${number}`, { method: 'GET', headers: headers })
            .then(response => response.json())
            .catch(error => { console.error('Error:', error) }).then(response => {
                let saldo = 0;
                setMovements(response.movements.map(i => ({ ...i, saldo: (saldo = saldo + (i.debit - i.credit)) })))
                setOpenModal(true);
            });
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const onSelectedFileClient = (file) => {
        fetch(`${URL_BASE}/api/save/credit`, { method: 'POST', headers: headers, body: file })
            .then(response => response.json())
            .catch(error => { console.error('Error:', error) })
            .then(response => {
                console.log(response.count);
                setSnackbarTitle(response.count > 0 ? 'Archivo de clientes y creditos procesado correctamente' : 'Ocurrio un error, por favor contacte con el administrador');
                setOpenSnak(true);
            });
    }

    const onSelectedFileMovement = (file) => {
        fetch(`${URL_BASE}/api/save/credit/movement`, { method: 'POST', headers: headers, body: file })
            .then(response => response.json())
            .catch(error => { console.error('Error:', error) })
            .then(response => {
                console.log(response.count);
                setSnackbarTitle(response.count > 0 ? 'Archivo de movimientos procesado correctamente' : 'Ocurrio un error, por favor contacte con el administrador');
                setOpenSnak(true);
            });
    }



    return <div>
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" className={classes.title}> ICTH </Typography>
                <div>
                    <div onClick={handleMenu} style={{ cursor: 'pointer' }}>
                        {userData && userData.name}
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"

                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>


                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                        <MenuItem onClick={() => {
                            localStorage.removeItem('token')
                            localStorage.removeItem('expires_in')
                            localStorage.removeItem('refresh_token')
                            localStorage.removeItem('token_type')
                            localStorage.removeItem('user')
                            props.history.push('/')
                        }}>Cerrar Sesion</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
        <div>
            {
                userData && userData.rol !== 'SUPER_ADMIN' && (
                    <>
                        <Paper className={classes.container}>
                            <h1>Extractos</h1>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fuente</TableCell>
                                        <TableCell>Numero</TableCell>
                                        <TableCell>Pagaduria</TableCell>
                                        <TableCell>Subcodigo</TableCell>
                                        <TableCell>Cantidad</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        extracts.map((value, key) => (
                                            <TableRow key={key} >
                                                <TableCell >{value.source}</TableCell>
                                                <TableCell >{value.number}</TableCell>
                                                <TableCell >{value.pagaduria}</TableCell>
                                                <TableCell >{value.subcode}</TableCell>
                                                <TableCell >
                                                    <CurrencyFormat value={value.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </TableCell>
                                                <TableCell >
                                                    <Button color="primary" size="small" onClick={() => handleOpenModal(value.source, value.number)} disabled={openModal}> Ver movimiento </Button>
                                                </TableCell>

                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                        </Paper>
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={openModal}
                            onClose={handleCloseModal}
                            closeAfterTransition
                            BackdropProps={{
                                timeout: 500,
                            }}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <Fade in={openModal}>
                                <div style={modalStyle} className={classes.paper}>
                                    <Grid container justify="space-between">
                                        <Grid item>
                                            <h2 id="simple-modal-title">Movimientos</h2>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={handleCloseModal} aria-label="delete">
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Debito</TableCell>
                                                <TableCell>Cedito</TableCell>
                                                <TableCell>Saldo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                movements.map((value, key) => (
                                                    <TableRow key={key} >
                                                        <TableCell >{value.date}</TableCell>
                                                        <TableCell >
                                                            <CurrencyFormat value={value.debit} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                        </TableCell>
                                                        <TableCell >
                                                            <CurrencyFormat value={value.credit} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                        </TableCell>
                                                        <TableCell >
                                                            <CurrencyFormat value={value.saldo} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                            </Fade>
                        </Modal>

                    </>
                )
            }
            {
                userData && userData.rol === 'SUPER_ADMIN' && (
                    <>
                        <Paper className={classes.container}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h2>Subir archivo</h2>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Drop title="Clientes y creditos" onSelectedFile={onSelectedFileClient} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Drop title="Movimientos" onSelectedFile={onSelectedFileMovement} />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={openSnak}
                            autoHideDuration={6000}
                            onClose={handleCloseSnak}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{snackbarTitle}</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={handleCloseSnak}
                                >
                                    <CloseIcon />
                                </IconButton>,
                            ]}
                        />
                    </>
                )
            }
        </div>
    </div>
}

const Drop = ({ title, onSelectedFile }) => {

    const classes = useStyles();

    const [file, setFile] = useState(null);

    const onDropHandler = ([file]) => {
        setFile(file)
    }

    return (
        <>
            <h3>{title}</h3>
            {
                !file && (
                    <Dropzone onDrop={onDropHandler}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <section>
                                <div className={classes.dropzone} {...getRootProps()}>
                                    <input {...getInputProps()} accept=".txt" />
                                    {
                                        isDragActive ?
                                            (<p>Suelta los archivos aquí ...</p>) :
                                            (<p>Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos</p>)
                                    }
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )
            }
            {
                file && (
                    <div className={classes.dropzone}>
                        {file.path}
                        <Grid container className={classes.buttonContainer} spacing={2} justify="center">
                            <Grid item>
                                <Button onClick={() => setFile(null)}>Cancelar</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => { onSelectedFile(file); setFile(null) }}>Subir</Button>
                            </Grid>

                        </Grid>

                    </div>
                )
            }
        </>
    )
}

export default Dash;