import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/components/footerStyle.js";
import { Link as LinkAnimate } from "react-scroll";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <LinkAnimate className={classes.block} to="aboutUs" smooth={true} duration={500}>
                ¿Quienes somos?
              </LinkAnimate>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <LinkAnimate className={classes.block} to="ourServices" smooth={true} duration={500}>
                Nuestro servicio
              </LinkAnimate>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <LinkAnimate className={classes.block} to="contact" smooth={true} duration={500}>
                Contactenos
              </LinkAnimate>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <NavLink className={classes.block} to={'politicas'}>
                Politicas
              </NavLink>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <NavLink className={classes.block} to={'organigrama'}>
                Organigrama
              </NavLink>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
