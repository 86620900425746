import React from "react";
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link as LinkAnimate } from "react-scroll";

import styles from '../../assets/jss/material-kit-react/components/headerLinksStyle';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const classes = useStyles();

    return (
        <List className={classes.list}>

            {
                !props.external && (
                    <>
                        <ListItem className={classes.listItem}>
                            <LinkAnimate className={classes.navLink} to="aboutUs" smooth={true} duration={500}>
                                ¿Quienes somos?
                            </LinkAnimate>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <LinkAnimate className={classes.navLink} to="ourServices" smooth={true} duration={500}>
                                Nuestro servicio
                            </LinkAnimate>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <LinkAnimate className={classes.navLink} to="contact" smooth={true} duration={500}>
                                Contactenos
                            </LinkAnimate>
                        </ListItem>

                    </>
                )
            }
            {
                props.external && (
                    <ListItem className={classes.listItem}>
                        <NavLink className={classes.navLink} to={''}>
                            Inicio
                        </NavLink>
                    </ListItem>
                )
            }
            <ListItem className={classes.listItem}>
                <NavLink className={classes.navLink} to={'politicas'}>
                    Politicas
                </NavLink>
            </ListItem>
            <ListItem className={classes.listItem}>
                <NavLink className={classes.navLink} to={'organigrama'}>
                    Organigrama
                </NavLink>
            </ListItem>
            <ListItem className={classes.listItem}>
                <NavLink className={classes.navLink} to={'ingresar'}>
                    Soy Cliente
                </NavLink>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button className={classes.navLink} href="http://190.131.221.172:3000/">Usuario</Button>
            </ListItem>
        </List>
    );
}


HeaderLinks.defaultProp = {
    external: false
};