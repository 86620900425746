import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import GridContainer from '../../../Components/Grid/GridContainer.js';
import GridItem from '../../../Components/Grid/GridItem.js';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import DraftsIcon from '@material-ui/icons/Drafts'
import BusinessIcon from '@material-ui/icons/Business'

const useStyles = makeStyles(styles);

export default function SectionContact(props) {
    const classes = useStyles();
    return (
        <div id='contact' className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Contactenos</h2>
                </GridItem>
            </GridContainer>
            <div style={{
                padding: '0 20px'
            }}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                        <div style={{ textAlign: "left" }}>
                            <h2><DraftsIcon className="contactIcon" />  servicioalclienteicthsas@gmail.com</h2>
                            <h2><DraftsIcon className="contactIcon" />  icthsas@gmail.com</h2>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <div style={{ textAlign: "left" }}>
                            <h2><PhoneInTalkIcon className="contactIcon" /> +57 316 741 1319</h2>
                            <h2><PhoneInTalkIcon className="contactIcon" /> +54 317 501 1496</h2>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7}>
                        <div style={{ textAlign: "left" }}>
                            <h2><BusinessIcon className="contactIcon" /> EDIFICIO CITYBANK CL 35 No 8B -05 OF 2D CENTRO</h2>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>

        </div >
    )
}