import React from 'react'

import { makeStyles } from '@material-ui/core';
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import GridContainer from '../../Components/Grid/GridContainer.js';
import GridItem from '../../Components/Grid/GridItem.js';
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'

const useStyles = makeStyles(styles);

export default function PoliticSection() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}>Políticas de seguridad y salud en el trabajo</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <Typography>
                                    Somos una empresa dedicada a otorgar créditos mediante el sistema de libranzas, de los bienes y valores que se comercialicen con recursos propios, y así lograr el bienestar socioeconómico de nuestros clientes y comunidad en general, por tal razón consideramos que la Seguridad y Salud en el Trabajo son importantes en la ejecución de nuestras actividades, siendo consecuentes con lo anterior la gerencia de la empresa define y establece la presente política.
                                </Typography>
                                <ul>
                                    <li>Todos los empleados son responsables de prevenir de manera prioritaria los accidentes de trabajo, las enfermedades laborales y los daños a la propiedad en todas las actividades controladas por la empresa.</li>
                                    <li>Asignar los recursos humanos, técnicos y financieros necesarios que permitan la implementación de programas basados en una mejora continua que contribuyan con el mejoramiento de la calidad de vida en el trabajo mediante la identificación de los peligros, evaluación, valoración y control permanente de los riesgos identificados en el SG-SST.</li>
                                    <li>Cumplir con los requisitos legales y cualquier otro suscrito por la empresa en Seguridad y Salud en el Trabajo.</li>
                                    <li>Todos los niveles de la organización son responsables de procurar el mantenimiento y mejoramiento continuo de altos estándares de Seguridad y Salud en el Trabajo en todas las actividades desarrolladas por la empresa.</li>
                                </ul>
                                <Typography>
                                    Esta política de Seguridad y Salud en el Trabajo debe ser conocida y practicada por todas las personas que conforman la empresa, por lo cual se divulga de forma efectiva y está disponible para todas las partes interesadas.
                                </Typography>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography className={classes.heading}>Políticas LAFTPADM</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <Typography>
                                    <b>(IVERSIONES Y COBRANZAS TODO HOGAR ICTH S.A.S) (en adelante ICTH S.A.S )</b> con el objetivo de orientar la conducta de los Accionistas, Empleados, Clientes y Proveedores en materia de prevención del riesgo de Lavado de Activos, Financiación del Terrorismo y Financiamiento de la Proliferación de Armas de Destrucción Masiva, establece los siguientes lineamientos para prevenir que la organización sea usada o pueda prestarse como medio en actividades relacionadas con LA/FT/FPADM:
                                </Typography>
                                <ul>
                                    <li>
                                        <b>ICTH S.A.S</b> dirige sus esfuerzos para mitigar el riesgo de Lavado de Activos, Financiación del Terrorismo y Financiamiento de la Proliferación de Armas de Destrucción Masiva, a través de la implementación de un SAGRILAFT el cual cumple con las disposiciones establecidas por la Circular Básica Jurídica 10000006 de 2020 establecida por la Superintendencia de Sociedades, así como con las buenas prácticas internacionales establecidas por el GAFI.
                                    </li>
                                    <li>
                                        Esta política se desarrolla a través del Manual SAGRILAFT y cada uno de los procedimientos del sistema, la cual establece que <b>ICTH S.A.S</b> (i) identifica y verifica la identidad de cada una de las contrapartes, (ii) identifica al beneficiario final, (iii) desarrolla cada una de las etapas del SAGRILAFT y (iv) construye base de datos que le permita consolidar e identificar alertas presentes o futuras
                                    </li>
                                    <li>
                                        <b>ICTH S.A.S</b> cuenta con procedimientos establecidos para el conocimiento de cada una de las Contrapartes, a través de la adopción de medidas razonables de Debida Diligencia; soportado en la obtención, registro y verificación de su información. Así mismo, tiene mecanismos establecidos para realizar un monitoreo continuo a la relación contractual.
                                    </li>
                                    <li>
                                        <b>ICTH S.A.S</b> establece requisitos específicos para las relaciones, con (i) Personas Expuestas Políticamente (PEP), (ii) para aquellas contrapartes que la organización considere que representan un mayor riesgo y (iii) a aquellas ubicadas en países no cooperantes y jurisdicciones de alto riesgo, con el objetivo de realizar una debida diligencia intensificada.
                                    </li>
                                    <li>
                                        <b>ICTH S.A.S</b> reporta en forma voluntaria ante la UIAF (Unidad de Información y Análisis Financiero) todas las operaciones que determine como sospechosas, conservando los soportes que dieron lugar a su calificación por un término no menor de diez (10) años. En caso de ausencia de operaciones sospechosas en un trimestre, el Oficial de Cumplimiento de <b>ICTH S.A.S</b> reporta ausencia de operaciones sospechosas a través de SIREL, dentro de los diez (10) días calendario siguientes al vencimiento del respectivo trimestre.
                                    </li>
                                    <li>
                                        <b>ICTH S.A.S</b> guarda reserva de la información reportada a las autoridades competentes, así como la información utilizada para el análisis de operaciones inusuales y sospechosas.
                                    </li>
                                    <li>
                                        <b>ICTH S.A.S</b> comprometida con el objetivo de crear una cultura institucional antilavado y anti financiación del terrorismo y proliferación de armas de destrucción masiva, capacita y divulga el sistema a sus empleados anualmente.
                                    </li>
                                    <li>
                                        <b>ICTH S.A.S</b> en su compromiso con el cumplimiento del SAGRILAFT, ha designado un Oficial de Cumplimiento Principal responsable de la auditoría y verificación del cumplimiento del sistema.
                                    </li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography className={classes.heading}>Políticas de tratamiento de datos personales</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <Typography>
                                    En cumplimiento de las obligaciones legales, en especial de la Ley 1581 de 2012 y sus decretos reglamentarios, la empresa INVERSIONES Y COBRANZAS TODO HOGAR ICTH S.A.S. (en adelante ICTH S.A.S) cuenta con una política de tratamiento de información personal que está disponible para los titulares. Además, ICTH S.A.S cuenta con un manual interno de procedimientos en el que se desarrolla la política y se explican claramente todos los parámetros y reglas que utiliza ICTH S.A.SS para garantizar el correcto tratamiento de los datos personales, en especial, el procedimiento que utiliza ICTH S.A.S para atender las quejas, consultas y reclamos presentados por los titulares de la información en ejercicio de su derecho de habeas data. La presente política de tratamiento de información personal está dispuesta para el conocimiento de los titulares. Para ello, ICTH S.A.S, como responsable de los datos, utiliza documentos, formatos electrónicos, medios verbales o cualquier otra tecnología, siempre y cuando garantice y cumpla con el deber de informar al titular de la información.
                                </Typography>
                                <br />
                                <Typography variant="h5" component="h2">1. OBJETIVO</Typography>
                                <Typography>
                                    Establecer los criterios para la recolección, almacenamiento, uso, circulación, verificación, corrección y supresión de los datos personales tratados por la sociedad ICTH S.A.S, identificada con el NIT 900.782.937-5 y con domicilio en la Calle 35 # 8B - 05 edificio CITIBANK OF. 2D en la ciudad de Cartagena, Colombia, con el fin de cumplir la legislación vigente en materia de protección de datos, en especial a la Ley 1581 de 2012 y demás decretos reglamentarios, así como las normas constitucionales para garantizar los derechos fundamentales al buen nombre y a la intimidad.
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">2. ALCANCE</Typography>
                                <Typography>
                                    Esta política aplica para toda la información personal registrada en las bases de datos de ICTH S.A.S, proveedores, empleados y demás personas naturales vinculadas o relacionadas con la ICTH S.A.S, como proveedor para el intercambio de activos virtuales en Colombia, quien actúa en calidad de responsable del tratamiento de los datos personales.
                                </Typography>
                                <br />
                                <Typography>
                                    La presente política se desarrolla a través del manual interno de procedimientos sobre el tratamiento de datos personales de ICTH S.A.S, el cual define las actividades específicas para el tratamiento de la información de cada una de las bases de datos, empleados, usuarios, aliados de negocio, proveedores, visitantes y demás personas relacionadas con ICTH S.A.S y periodo de vigencia de estas.
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">3. OBLIGACIONES</Typography>
                                <Typography>
                                    Esta política es de obligatorio y estricto cumplimiento para ICTH S.A.S como responsable del tratamiento de los datos personales.
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">4. PRINCIPIOS PARA EL TRATAMIENTO DE LOS DATOS PERSONALES</Typography>
                                <Typography>
                                    En el desarrollo de la presente política ICTH S.A.S aplica, de manera armónica e integral, los siguientes principios:<br />
                                    a) Principio de legalidad: El tratamiento de datos personales es una actividad reglada que debe sujetarse a lo establecido en la Ley 1581 de 2012 y demás normas que lo desarrollen; <br />
                                    b) Principio de finalidad: El tratamiento de datos personales debe obedecer a una finalidad legítima de acuerdo con la Constitución y la Ley, la cual debe ser informada al titular de la información; <br />
                                    c) Principio de libertad: El tratamiento de datos personales sólo puede ejercerse con el consentimiento, previo, expreso e informado del titular de la información. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento; <br />
                                    d) Principio de veracidad o calidad: La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error; <br />
                                    e) Principio de transparencia: En el tratamiento de datos personales debe garantizarse el derecho del titular a obtener del responsable del tratamiento o del encargado del tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan; <br />
                                    f) Principio de acceso y circulación restringida: El tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones establecidas en la ley y la Constitución. En este sentido, el tratamiento de datos personales sólo podrá hacerse por personas autorizadas por el titular de la información y/o por las personas previstas en la ley; Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los titulares o terceros autorizados en la ley; <br />
                                    g) Principio de seguridad: La información sujeta a tratamiento por el responsable del tratamiento o encargado del tratamiento a que se refiere la ley, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros con el fin de evitar su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento; <br />
                                    h) Principio de confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley.<br />
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">5. RESPONSABLE Y ENCARGADO DEL TRATAMIENTO</Typography>
                                <Typography>
                                    El responsable y encargado del tratamiento de datos personales es la sociedad ICTH S.A.S., legalmente constituida en Colombia, identificada con el NIT
                                    900.782.937-5 y con domicilio principal en la Calle 35 # 8B - 05 edificio CITIBANK OF. 2D en la ciudad de Cartagena, Colombia. Correo electrónico icthsas@gmail.com y teléfono 3464712. Lo anterior, toda vez que ICTH S.A.S recauda la información y toma las decisiones sobre el tratamiento de los datos personales.
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">6. AUTORIZACIÓN PREVIA PARA EL TRATAMIENTO DE DATOS PERSONALES</Typography>
                                <Typography>
                                    Para el tratamiento de los datos personales de los titulares, ICTH S.A.S S.A.S. dispone de diferentes medios para obtener su autorización de manera previa expresa e informada, ya sea verbal, escrita o mediante conductas inequívocas. Dicha autorización se solicita al titular, a más tardar, en el momento de la recolección de sus datos personales. Además, ICTH S.A.S conservará el soporte de la autorización obtenida conforme a lo dispuesto en la Ley 1581 de 2012 y demás normas vigentes.
                                </Typography>
                                <br />
                                <Typography>
                                    Está prohibido realizar -por parte de ICTH S.A.S, sus dependencias, áreas, contratistas y demás colaboradores que tengan acceso a la información- tratamiento alguno de los datos personales de los titulares sin contar con su autorización, salvo en los casos establecidos en los artículos 2 y 10 de la ley 1581 de 2012, así:
                                </Typography>
                                <br />
                                <Typography>
                                    El artículo 2 de la Ley 1581 de 2012 establece las siguientes excepciones al régimen de protección de datos personales:
                                </Typography>
                                <Typography>
                                    a) A las bases de datos o archivos mantenidos en un ámbito exclusivamente personal o doméstico. Cuando estas bases de datos o archivos vayan a ser suministrados a terceros se deberá, de manera previa, informar al Titular y solicitar su autorización. En este caso los Responsables y Encargados de las
                                    bases de datos y archivos quedarán sujetos a las disposiciones contenidas en la ley.<br />
                                    b) A las bases de datos y archivos que tengan por finalidad la seguridad y defensa nacional, así como la prevención, detección, monitoreo y control, del lavado de activos y el financiamiento del terrorismo.<br />
                                    c) A las Bases de datos que tengan como fin y contengan información de inteligencia y contrainteligencia.<br />
                                    d) A las bases de datos y archivos de información periodística y otros contenidos editoriales.<br />
                                    e) A las bases de datos y archivos regulados por la Ley 1266 de 2008.<br />
                                    f) A las bases de datos y archivos regulados por la Ley 79 de 1993.<br />
                                </Typography>
                                <Typography>
                                    En el mismo sentido, el artículo 10 de la Ley 1581 de 2012 establece los siguientes casos en los que no se requiere la autorización del titular:
                                </Typography>
                                <Typography>
                                    a) Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial.<br />
                                    b) Datos de naturaleza pública.<br />
                                    c) Casos de urgencia médica o sanitaria.<br />
                                    d) Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos.<br />
                                    e) Datos relacionados con el Registro Civil de las Personas.<br />
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">7. TRATAMIENTO Y FINALIDAD</Typography>
                                <Typography>
                                    El tratamiento que realiza la sociedad ICTH S.A.S con la información personal, según el tipo de base de datos que se trate, es la de recolectar, almacenar, tratar, usar, custodiar, archivar, recopilar, consultar, analizar, verificar, reportar, suministrar, suprimir, compartir, transmitir y transferir para:
                                </Typography>
                                <ul>
                                    <li>Efectuar las gestiones pertinentes para el desarrollo del objeto social de ICTH S.A.S en lo que tiene que ver con el intercambio de activos virtuales, así como las demás relacionadas con la celebración del contrato celebrado con el titular de la información.</li>
                                    <li>Realizar invitaciones a eventos comerciales y ofrecer nuevos productos y servicios de ICTH S.A.S.</li>
                                    <li>Gestionar todo tipo de trámites, en especial solicitudes, quejas y reclamos.</li>
                                    <li>Efectuar encuestas de satisfacción respecto de los bienes y servicios ofrecidos por ICTH S.A.S.</li>
                                    <li>Suministrar información de contacto a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y cualquier tercero con el cual ICTH S.A.S tenga un vínculo contractual para el desarrollo de actividades de ese tipo (investigación de mercados y telemercadeo, etc.) para la ejecución de estas.</li>
                                    <li>Contactar al titular de la información a través de medios telefónicos para realizar encuestas, estudios y/o confirmación de datos personales necesarios para la ejecución de una relación contractual con ICTH S.A.S.</li>
                                    <li>Contactar al titular de la información a través de medios electrónicos – SMS o chat para el envío de noticias relacionadas con campañas de fidelización o mejora de servicio de ICTH S.A.S.</li>
                                    <li>Contactar al titular de la información a través de correo electrónico para el envío de extractos, estados de cuenta, facturas o cualquier otra información en relación con las obligaciones derivadas del contrato celebrado con ICTH S.A.S.</li>
                                    <li>Dar cumplimiento a las obligaciones contraídas por ICTH S.A.S con el titular de la información, con relación al pago de salarios, prestaciones sociales y demás retribuciones consagradas en el contrato de trabajo o según lo disponga la ley (en caso de que se trate de colaboradores de ICTH S.A.S).</li>
                                    <li>Ofrecer programas de bienestar corporativo y planificar actividades empresariales de ICTH S.A.S para el titular y sus beneficiarios tales como hijos, cónyuge, compañero permanente, etc. (en caso de que se trate de colaboradores de la ICTH S.A.S.</li>
                                    <li>Transmitir los datos personales fuera del país a terceros con los cuales ICTH S.A.S haya suscrito o suscribiere un contrato de procesamiento de datos y sea necesario entregársela para el cumplimiento del objeto contractual, siempre y cuando el país o tercero deba cumplir con normas relativas al tratamiento de datos personales.</li>
                                    <li>Suministrar la información a terceros con los cuales ICTH S.A.S tenga relación contractual y que sea necesario entregársela para el cumplimiento del objeto contratado.</li>
                                </ul>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">8. TRATAMIENTO DE DATOS SENSIBLES</Typography>
                                <Typography>
                                    Un dato sensible es aquel que afecta la intimidad del titular de la información y puede dar lugar a que sea discriminado, como aquellos que revelan su origen racial o étnico, su orientación política, las convicciones religiosas o filosóficas, organizaciones sociales, de derechos humanos, así como los datos relativos a la salud, a la vida sexual, y los datos biométricos.
                                </Typography>
                                <br />
                                <Typography>
                                    Los datos sensibles recolectados por ICTH S.A.S son tratados con las siguientes finalidades según el tipo de base de datos que se trate:
                                </Typography>
                                <ul>
                                    <li>Efectuar las gestiones pertinentes para el desarrollo del objeto social de ICTH S.A.S en lo que tiene que ver con el intercambio de activos virtuales, así como las demás relacionadas con la celebración del contrato celebrado con el titular de la información.</li>
                                    <li>Realizar invitaciones a eventos comerciales y ofrecer nuevos productos y servicios de ICTH S.A.S.</li>
                                    <li>Gestionar todo tipo de trámites, en especial solicitudes, quejas y reclamos.</li>
                                    <li>Efectuar encuestas de satisfacción respecto de los bienes y servicios ofrecidos por ICTH S.A.S.</li>
                                    <li>Suministrar información de contacto a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y cualquier tercero con el cual ICTH S.A.S tenga un vínculo contractual para el desarrollo de actividades de ese tipo (investigación de mercados y telemercadeo, etc.) para la ejecución de estas.</li>
                                    <li>Contactar al titular de la información a través de medios telefónicos para realizar encuestas, estudios y/o confirmación de datos personales necesarios para la ejecución de una relación contractual con ICTH S.A.S.</li>
                                    <li>Contactar al titular de la información a través de medios electrónicos – SMS o chat para el envío de noticias relacionadas con campañas de fidelización o mejora de servicio de ICTH S.A.S.</li>
                                    <li>Contactar al titular de la información a través de correo electrónico para el envío de extractos, estados de cuenta, facturas o cualquier otra información en relación con las obligaciones derivadas del contrato celebrado con ICTH S.A.S.</li>
                                    <li>Dar cumplimiento a las obligaciones contraídas por ICTH S.A.S con el titular de la información, con relación al pago de salarios, prestaciones sociales y demás retribuciones consagradas en el contrato de trabajo o según lo disponga la ley (en caso de que se trate de colaboradores de la ICTH S.A.S)</li>
                                    <li>Ofrecer programas de bienestar corporativo y planificar actividades empresariales de ICTH S.A.S para el titular y sus beneficiarios tales como hijos, cónyuge, compañero permanente, etc. (en caso de que se trate de colaboradores de la ICTH S.A.S)</li>
                                    <li>Transmitir los datos personales fuera del país a terceros con los cuales ICTH S.A.S haya suscrito o suscribiere un contrato de procesamiento de datos y sea necesario entregársela para el cumplimiento del objeto contractual, siempre y cuando el país o tercero deba cumplir con normas relativas al tratamiento de datos personales.</li>
                                    <li>Suministrar la información a terceros con los cuales ICTH S.A.S tenga relación contractual y que sea necesario entregársela para el cumplimiento del objeto contratado.</li>
                                </ul>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">9. TRATAMIENTO DE DATOS DE MENORES DE EDAD</Typography>
                                <Typography>
                                    ICTH S.A.S dispone de medidas de seguridad para brindar una protección reforzada a los datos de menores de edad recolectados de manera que se asegure el respeto a los derechos prevalentes de los niños, niñas y adolescentes.
                                    <br />
                                    En todo caso se solicitará autorización previa, expresa e informada al titular, tutor o representante del menor de edad antes de iniciar la recolección de datos personales. El titular no está obligado a suministrar o autorizar dicho tratamiento, salvo en los casos que por ley no sea requerido el otorgamiento de dicha autorización, o el Tratamiento sea necesario para salvaguardar el interés vital del Titular y este se encuentre física o jurídicamente incapacitado, casos de urgencia médica o sanitaria.
                                    <br />
                                    ICTH S.A.S realiza el tratamiento de los datos personales de niños, niñas y menores de edad con el fin de garantizar sus derechos; ofrecer servicios administrativos o culturales que ofrezca la empresa; actividades que impliquen la toma de fotografías o videos para su difusión; afiliación al sistema de seguridad social o entidades públicas similares; y, en general, actividades de bienestar laboral como hijos menores y adolescentes de los colaboradores o de cualquier otra persona relacionada con la empresa. Dicho tratamiento se realiza siempre y cuando:
                                    <br /> a) la finalidad del tratamiento responda al interés superior de los niños, niñas y adolescentes;
                                    <br /> b) el tratamiento realizado por ICTH S.A.S asegura el respeto de sus derechos fundamentales;
                                    <br /> c) se respete el derecho que le asiste al menor de ser escuchado, opinión que será valorada teniendo en cuenta la madurez, autonomía y capacidad para entender el tratamiento del dato;
                                    <br /> d) se cumpla con los requisitos previstos en la Ley 1581 de 2012 para el tratamiento de datos personales.
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">10. DERECHOS DEL TITULAR DE LA INFORMACIÓN</Typography>
                                <Typography>
                                    Como titular de sus datos personales usted tiene derecho a:
                                    <br />1) Acceder en forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento por parte de ICTH S.A.S.
                                    <br />2) Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o a aquellos cuyo tratamiento esté prohibido o no haya sido autorizado.
                                    <br />3) Solicitar prueba de la autorización otorgada a ICTH S.A.S.
                                    <br />4) Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente, luego de que ICTH S.A.S no haya cumplido los términos establecidos en la ley para atender las solicitudes del titular.
                                    <br />5) Revocar la autorización y/o solicitar la supresión de cualquier dato, siempre que no exista un deber legal o contractual que impida eliminarlos.
                                    <br />6) Abstenerse de responder las preguntas sobre datos sensibles. Así mismo, las respuestas sobre datos sensibles o sobre niñas y niños y adolescentes son de carácter facultativo.
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">11. ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS</Typography>
                                <Typography>
                                    El Oficial de Protección de Datos Personales y su equipo de apoyo es el encargado de la atención de consultas y reclamos, así como la dependencia responsable de dar trámite a las solicitudes de los titulares para hacer efectivos sus derechos.
                                    <br />
                                    Para tal fin, los titulares de la información pueden ejercer sus derechos a través de los siguientes canales o medios dispuestos por ICTH S.A.S S.A.S. para la atención al público:
                                    <br />
                                    Al teléfono 3464712, por medio del correo electrónico
                                    <br />
                                    icthsas@gmail.com y la oficina ubicada en la Calle 35 # 8B - 05 edificio CITIBANK OF. 2D en la ciudad de Cartagena, Colombia, en horario de 7:00 a.m. a 5:30 p.m.
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">12. MEDIDAS DE SEGURIDAD EN EL TRATAMIENTO DE DATOS PERSONALES</Typography>
                                <Typography>
                                    ICTH S.A.S adopta las medidas de seguridad necesarias y apropiadas para proteger la información personal de los titulares.
                                    <br />
                                    En cumplimiento del principio de seguridad, la información sujeta a tratamiento por parte de ICTH S.A.S, como responsable y encargado del tratamiento, se maneja con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros y evitar su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                                    <br />
                                    De esta forma, ICTH S.A.S como responsable y/o encargado del tratamiento toma las medidas acordes con el sistema de información correspondiente -entre ellas la protección de acceso con contraseñas; diferentes roles según el nivel de autoridad; complejidad y vigencia de contraseñas para usuarios; software de cifrado de datos, etc.- y establece controles de seguridad, de acuerdo con el tipo de base de datos que se trate, que permita garantizar los estándares de protección consagrados en la ley.
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">13. PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA</Typography>
                                <Typography>
                                    En cumplimiento de las normas legales vigentes sobre protección de datos personales, ICTH S.A.S, como responsable del tratamiento, presenta el procedimiento y requisitos mínimos para el ejercicio de sus derechos:
                                    <br />
                                    Solicitudes para consultar datos personales
                                    <br />
                                    Los titulares de la información, sus causahabientes, sus representantes o apoderados, pueden consultar la información personal del titular que repose en las bases de datos de ICTH S.A.S.
                                    <br />
                                    ICTH S.A.S como responsable y/o encargada del tratamiento de los datos personales suministra la información solicitada que se encuentre contenida la base de datos o la que esté vinculada con la identificación del titular.
                                    <br />
                                    El titular debe acreditar su condición mediante la exhibición o suministro de la copia del documento de identificación pertinente que puede suministrar en medio físico o digital. En caso de que el titular esté representado por un tercero, debe allegar el respectivo poder, que debe contener el respectivo reconocimiento ante notario; a su vez, el apoderado debe igualmente acreditar su identidad en los términos antes indicados.
                                    <br />
                                    La solicitud de consulta de datos se formula a través de los canales que para dicho efecto han sido habilitados por ICTH S.A.S y en especial a través de comunicación escrita o electrónica, dirigida a la dependencia y persona indicada en el numeral anterior de la presente política.
                                    La consulta de datos es atendida por ICTH S.A.S en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de esta. Cuando no fuere posible para ICTH S.A.S atender la consulta dentro de dicho término, lo informa al interesado, en el que se expresa los motivos de la demora y se señala la fecha en que atiende su consulta, la cual en ningún caso supera los cinco (5) días hábiles siguientes al vencimiento del primer término.
                                    Los datos personales se pueden consultar de forma gratuita al menos una vez cada mes calendario, y cada vez que existan modificaciones sustanciales de las políticas establecidas por ICTH S.A.S que motiven nuevas consultas.
                                    <br />
                                    Para consultas cuya periodicidad sea mayor a una por cada mes calendario, ICTH S.A.S puede cobrar al titular de la información los gastos de envío, reproducción y, en su caso, certificación de documentos.
                                    <br />
                                    Solicitudes para reclamos sobre los datos personales
                                    <br />
                                    Los titulares, sus causahabientes, sus representantes o apoderados pueden presentar una solicitud de reclamo cuando consideren que la información que se encuentra contenida en las bases de datos de ICTH S.A.S, como responsable y/o encargada del tratamiento, debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la ley.
                                    <br />
                                    Para la radicación y atención de la solicitud de reclamo se debe suministrar la siguiente información:
                                    <br />
                                </Typography>
                                <ul>
                                    <li>Nombre completo y apellidos.</li>
                                    <li>Datos de contacto (dirección física y/o electrónica y teléfonos de contacto).</li>
                                    <li>Medios (dirección física y/o electrónica u otro medio) para recibir respuesta a su solicitud.</li>
                                    <li>Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información).</li>
                                    <li>Firma (si aplica) y número de identificación.</li>
                                </ul>
                                <Typography>
                                    La solicitud de reclamo sobre datos personales se debe formular a través de los canales que para dicho efecto han sido habilitados por ICTH S.A.S
                                    y en especial a través de comunicación escrita o electrónica, dirigida a la dependencia y persona indicada en el numeral anterior de la presente política.
                                    <br />
                                    Si el reclamo resulta incompleto, ICTH S.A.S se requiere al interesado dentro de los cinco (5) días hábiles siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento realizado por la ICTH S.A.S, sin que el solicitante presente la información requerida, ICTH S.A.S entiende que se ha desistido del reclamo.
                                    <br />
                                    En caso de que quien reciba el reclamo no sea competente para resolverlo, da traslado a quien corresponda en un término máximo de dos (2) días hábiles e informa de la situación al interesado.
                                    Una vez ICTH S.A.S reciba el reclamo completo, incluye en la base de datos una leyenda que indica: "reclamo en trámite" y el motivo de este, en un término no mayor a dos (2) días hábiles. Dicha leyenda debe mantenerse hasta que el reclamo sea resuelto.
                                    <br />
                                    El término máximo previsto por la ley para resolver la solicitud de reclamación es de quince (15) días hábiles, contado a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, ICTH S.A.S informa al interesado los motivos de la demora y la fecha en que se atiende su reclamo, la cual en ningún caso puede superar los ocho (8) días hábiles siguientes al vencimiento del primer término.
                                    <br />
                                    Una vez cumplidos los términos señalados por la Ley 1581 de 2012 y las demás normas que la reglamenten o complementen, el titular de la información al que se deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, supresión y revocación puede poner su caso en conocimiento de la Superintendencia de Industria y Comercio (SIC) – Delegatura para la Protección de Datos Personales.
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">14. DEBERES COMO RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE LOS DATOS PERSONALES</Typography>
                                <br />
                                <Typography>
                                    ICTH S.A.S como responsable del tratamiento de los datos personales de los titulares de la información asume los siguientes deberes:
                                    <br />
                                    <br />a) Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.
                                    <br />b) Solicitar y conservar, en las condiciones previstas en la ley, copia de la respectiva autorización otorgada por el titular.
                                    <br />c) Informar debidamente al titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada.
                                    <br />d) Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                                    <br />e) Garantizar que la información que se suministre al encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.
                                    <br />f) Actualizar la información, comunicando de forma oportuna al encargado del tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a éste se mantenga actualizada.
                                    <br />g) Rectificar la información cuando sea incorrecta y comunicar lo pertinente al encargado del tratamiento.
                                    <br />h) Suministrar al encargado del tratamiento, según el caso, únicamente datos cuyo tratamiento esté previamente autorizado de conformidad con lo previsto en la ley.
                                    <br />i) Exigir al encargado del tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del titular.
                                    <br />j) Tramitar las consultas y reclamos formulados en los términos señalados en la ley.
                                    <br />k) Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y en especial, para la atención de consultas y reclamos.
                                    <br />l) Informar al encargado del tratamiento cuando determinada información se encuentra en discusión por parte del titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.
                                    <br />m) Informar a solicitud del titular sobre el uso dado a sus datos.
                                    <br />n) Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.
                                    <br />o) Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.
                                    <br />
                                    ICTH S.A.S como encargado del tratamiento de los datos personales de los titulares de la información asume los siguientes deberes:
                                    <br />
                                    <br />a) Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.
                                    <br />b) Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                                    <br />c) Realizar oportunamente la actualización, rectificación o supresión de los datos en los términos de la ley.
                                    <br />d) Actualizar la información reportada por los responsables del tratamiento dentro de los cinco (5) días hábiles contados a partir de su recibo.
                                    <br />e) Tramitar las consultas y los reclamos formulados por los titulares en los términos señalados en la ley.
                                    <br />f) Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y, en especial, para la atención de consultas y reclamos por parte de los titulares.
                                    <br />g) Registrar en la base de datos la leyenda “reclamo en trámite” en la forma en que se regula en la ley cuando se tramita tal solicitud.
                                    <br />h) Insertar en la base de datos la leyenda “información en discusión judicial” una vez notificado por parte de la autoridad competente sobre procesos judiciales relacionados con la calidad del dato personal.
                                    <br />i) Abstenerse de circular información que esté siendo controvertida por el titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio.
                                    <br />j) Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella.
                                    <br />k) Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.
                                    <br />l) Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.
                                    <br />
                                </Typography>

                                <br />
                                <br />
                                <Typography variant="h5" component="h2">15. VIGENCIA</Typography>
                                <br />
                                <Typography>
                                    La presente Política para el Tratamiento de Datos Personales de ICTH S.A.S S.A.S. rige a partir de 12 de agosto de 2021
                                </Typography>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </GridItem>
            </GridContainer>
        </div>
    )
}