import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import GridContainer from '../../../Components/Grid/GridContainer.js';
import GridItem from '../../../Components/Grid/GridItem.js';
import InfoArea from '../../../Components/InfoArea/InfoArea.js';

import Timer from "@material-ui/icons/Timer";
import CallSplit from "@material-ui/icons/CallSplit";
import HowToReg from "@material-ui/icons/HowToReg";
import DateRange from "@material-ui/icons/DateRange";
import Description from "@material-ui/icons/Description";
import Timelapse from "@material-ui/icons/Timelapse";

const useStyles = makeStyles(styles);

export default function SectionGoodnessCredits(props) {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Bondades de nuestros créditos por el sistema de libranzas</h2>
                    <h5 className={classes.description}>
                        Docentes de las secretarias de Educación Distrital de Cartagena, de Bolívar  y pensionados de Fiduciaria La Previsora  y FOPEP,
                        este es tu momento de solucionar tu inconveniente de efectivo; sin mayores tramites suple te necesidad, contáctanos y obtén de inmediato
                        tu crédito por libranza.
                    </h5>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            title="Aprobación Ágil y Segura"
                            description="contamos con un óptimo soporte tecnológico para brindarle con mayor rapidez y seguridad la aprobación de sus solicitudes en el menor tiempo posible."
                            icon={Timer}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            title="Flexibles con centrales de riesgo"
                            description="Si posee un score bajo, estamos dispuestos a analizar y ser condescendientes con ciertas carteras para que pueda acceder a su crédito por libranza."
                            icon={CallSplit}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            title="Asesoría personalizada"
                            description="uno de nuestros fuertes es la calidad humana que encontrarás en cada integrante de la familia ICTH SAS, le mostraremos las mejores opciones para que realice su solicitud de manera clara y oportuna"
                            icon={HowToReg}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12}></GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            title="Plazos Convenientes"
                            description="El plazo lo decide usted, de acuerdo a su necesidad o capacidad de pago, es importante que lo escoja a su conveniencia."
                            icon={DateRange}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            title="Documentación Mínima"
                            description="Con tan sólo su desprendible de pago y fotocopia de cédula ampliada al 150 % accede a tu solicitud, reduciendo documentos que no son relevantes."
                            icon={Description}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            title="Rápido Desembolso"
                            description="El tiempo mínimo es de 4 horas, dependiendo de la pagaduría y Máximo es de 48 horas si es compra de cartera."
                            icon={Timelapse}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    )
}