import React from 'react'
import Header from '../../Components/Header/Header'
import HeaderLinks from '../../Components/Header/HeaderLinks'
import { makeStyles } from '@material-ui/core';
import classNames from "classnames";
import organigrama from './organigrama.png'
import styles from "../../assets/jss/material-kit-react/views/components";
import Footer from '../../Components/Footer/Footer';
import Typography from '@material-ui/core/Typography'
const useStyles = makeStyles(styles);

export default function OrganizationChart(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (
        <div>
            <Header
                brand="ICTH"
                rightLinks={<HeaderLinks external />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <div className={classNames(classes.main, classes.top)}>
                <Typography variant="h3" gutterBottom style={{ textAlign: 'center', marginBottom: 20 }}>Organigrama</Typography>
                <div style={{ textAlign: 'center' }}>
                    <img style={{ maxWidth: 1200, marginLeft: 80 }} className="organigrama" src={organigrama} alt="organigrama" />
                </div>
            </div>
            <Footer />
        </div>
    )
}