import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import './Login.css'
import { Button, Paper } from '@material-ui/core'
import logo from './icth-logo-login.png'
import PersonIcon from '@material-ui/icons/Person'
import { URL_BASE, grant_type, client_id, client_secret } from '../../config'
import { Redirect } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { NavLink } from 'react-router-dom'
import { Fab } from '@material-ui/core';

export default function Login(props) {
    const [state, setState] = useState({
        username: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [textError, setTextError] = useState('');

    const handleInputChange = e => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setState({ ...state, [name]: value });
    }

    const { username, password } = state;

    return (
        localStorage.getItem('token') ?
            <Redirect to={'app'} />
            :
            <div className="loginWrapper">
                <NavLink to={'/'} className="link">
                    <Fab color="primary" aria-label="regresar">
                        <ArrowBackIcon className="icon" />
                    </Fab>
                </NavLink>
                <form
                    onSubmit={e => {
                        e.preventDefault()
                        setTextError('')
                        setLoading(true);

                        var formData = new FormData();

                        formData.append('grant_type', grant_type)
                        formData.append('client_id', client_id)
                        formData.append('client_secret', client_secret)
                        formData.append('username', username)
                        formData.append('password', password)
                        formData.append('scope', '')


                        fetch(`${URL_BASE}/oauth/token`, {
                            method: 'POST',
                            body: formData
                        })
                            .then(response => response.json())
                            .catch(error => {
                                setLoading(false)
                                setTextError('Algo salio mal')
                                console.error('Error:', error)
                            })
                            .then(response => {
                                setLoading(false);
                                if (response.access_token) {
                                    localStorage.setItem('token', response.access_token)
                                    localStorage.setItem('expires_in', response.expires_in)
                                    localStorage.setItem('refresh_token', response.refresh_token)
                                    localStorage.setItem('token_type', response.token_type)
                                    props.history.push('/app')
                                }
                                setTextError('Error usuario y contarseña incorrecta')

                            })
                    }}
                >
                    <img className="logo" src={logo} alt="logo" />
                    <Paper className="paper" >
                        <div>
                            <div className="icon-circle">
                                <PersonIcon className="icon" />
                            </div>
                            <h3>Iniciar Sesión</h3>
                        </div>
                        <div>
                            <TextField
                                id="username"
                                name='username'
                                label="Usuario"
                                value={username}
                                onChange={handleInputChange}
                                margin="normal"
                                className="TextField"
                                variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                                id="password"
                                name="password"
                                label="Contraseña"
                                type="password"
                                value={password}
                                onChange={handleInputChange}
                                margin="normal"
                                className="TextField"
                                variant="outlined"
                            />
                        </div>
                        {textError !== '' && textError}
                        <div className="loginButtonContainer">
                            <Button
                                color="primary"
                                disabled={loading}
                                type="submit"
                            >
                                Ingresar
                            </Button>
                        </div>
                    </Paper>
                </form>
            </div >
    )
}