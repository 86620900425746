import { title } from "../../../material-kit-react.js";

const productStyle = theme => ({
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  sectionOurServices: {
    background: "rgb(181,212,186)",
    background: "linear-gradient(130deg, rgba(135,159,191,1) 0%, rgba(22,50,86,1) 100%)"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: '40px'
  },
  description: {
    color: "#767676",
    fontSize: '20px',
    padding: '0 15px'
  },
  colorWhite: {
    color: "#fff"
  },
  img: {
    maxWidth: '150px',
    display: 'block',
    margin: '0 auto'
  },
  paper: {
    padding: '10px',
    marginBottom: '10px'
  },
  up: {
    minHeight: '200px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    flexShrink: 0,
  },
});

export default productStyle;
